<template>
    <div class="flex">
        <div class="card card-f-h mr-4 w-72">
            <SideBar
                @newEmail="newEmailVisible = true"
                @inboxClicked="sideBarClicked"
            />
        </div>

        <Subjects
            v-show="!selectedEmailUuid && !newEmailVisible"
            :selectedEmailUuid="selectedEmailUuid"
            :inboxType="inboxType"
            @selected="selectionChanged"
        />

        <EmailContent
            v-show="selectedEmailUuid && !newEmailVisible"
            :selectedEmailUuid="selectedEmailUuid"
            @goBack="selectedEmailUuid = null"
        />
        <ComposeNewEmail
            v-show="newEmailVisible"
            @goBack="newEmailVisible = false"
        />
    </div>
</template>
<script>
export default {
    components: {
        SideBar:         () => import(/* webpackChunkName: "Emails/SideBar" */ './components/SideBar'),
        Subjects:        () => import(/* webpackChunkName: "Emails/Subjects" */ './components/Subjects'),
        EmailContent:    () => import(/* webpackChunkName: "Emails/EmailContent" */ './components/EmailContent'),
        ComposeNewEmail: () => import(/* webpackChunkName: "Emails/ComposeNewEmail" */ './components/ComposeNewEmail'),
    },

    data() {
        return {
            selectedEmailUuid: null,
            newEmailVisible:   false,
            inboxType:         'inbox_from_clients',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('communication.communication_emails'));
        this.$store.commit('setActiveMenuItem', '7-1');
    },

    methods: {
        selectionChanged(uuid) {
            this.selectedEmailUuid = uuid;
        },

        sideBarClicked(inboxType) {
            this.selectedEmailUuid = null;
            this.newEmailVisible = false;
            this.inboxType = inboxType;
        },
    },
};
</script>
